$main-bg: #161616;
$secondary-bg: #1B1B1B;
$table-header-bg: $secondary-bg;

.main-background {
  background: $main-bg;
}

.second-background {
  background: $secondary-bg;
}

.pe-md-10px {
  padding-right: 10px !important;
}

.ps-md-10px {
  padding-left: 10px !important;
}

.g-md-20px {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 20px;
}


input:focus, button:focus {
  outline: none !important;
}
//
//.btn-primary {
//  $btn-disabled-opacity: 1;
//  @include button-variant(
//          $background: #fff,
//          $border: $white,
//          $color: $main-bg,
//          $hover-background: rgba(255, 255, 255, 0.60),
//          $hover-border: rgba(255, 255, 255, 0.30),
//          $hover-color: $main-bg,
//          $active-background: rgba(255, 255, 255, 0.60),
//          $active-color: $main-bg,
//          $active-border: rgba(255, 255, 255, 0.40),
//          $disabled-background: rgba(255, 255, 255, 0.40),
//          $disabled-color: $main-bg,
//          $disabled-border: rgba(255, 255, 255, 0.20),
//  );
//  border-radius: 100px;
//  font-size: 13px;
//  line-height: 16px;
//}
//
//
//.btn-secondary {
//  $btn-disabled-opacity: 1;
//  @include button-variant(
//          $background: rgba(255, 255, 255, 0.15),
//          $border: rgba(255, 255, 255, 0.05),
//          $color: $white,
//          $hover-background: rgba(255, 255, 255, 0.40),
//          $hover-border: rgba(255, 255, 255, 0.20),
//          $hover-color: $white,
//          $active-background: rgba(255, 255, 255, 0.40),
//          $active-color: $white,
//          $active-border: rgba(255, 255, 255, 0.20),
//          $disabled-background: rgba(255, 255, 255, 0.15),
//          $disabled-color: $secondary,
//          $disabled-border: rgba(255, 255, 255, 0.05),
//  );
//  border-radius: 100px;
//  font-size: 13px;
//  line-height: 16px;
//}

.btn-bot {
  min-width: 158px;
  $btn-disabled-opacity: 1;
  @include button-variant(
          $background: $table-header-bg,
          $border: $table-header-bg,
          $color: $white,
          $hover-background: #292929,
          $hover-border: #292929,
          $hover-color: $white,
          $active-background: $table-header-bg,
          $active-color: $white,
          $active-border: $table-header-bg,
          $disabled-background: $table-header-bg,
          $disabled-color: $secondary,
          $disabled-border: $table-header-bg,
  );
  text-align: left;
  border-radius: 10px;
  padding: 8px 8px 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.24px;

  img:first-child {
    margin-right: 8px;
  }
}

.btn-inner {
  min-width: 158px;
  $btn-disabled-opacity: 1;
  @include button-variant(
          $background: $table-header-bg,
          $border: $table-header-bg,
          $color: $white,
          $hover-background: #292929,
          $hover-border: #292929,
          $hover-color: $white,
          $active-background: $table-header-bg,
          $active-color: $white,
          $active-border: $table-header-bg,
          $disabled-background: $table-header-bg,
          $disabled-color: $white,
          $disabled-border: $table-header-bg,
  );
  text-align: left;
  padding: 8px 8px 8px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.24px;

  img:first-child {
    margin-right: 8px;
  }

  &.active {
    background-image: url("../../../assets/whale-signals/tick.svg");
    background-position: center right 10px;
    background-repeat: no-repeat;
    background-size: 16px;
  }

  &[disabled] {
    opacity: 1
  }

  &[disabled] * {
    opacity: 0.4
  }
}


//body {
//  min-height: 100vh;
//  display: flex;
//  flex-direction: column;
//}
//
//.content {
//  flex: 1;
//  justify-content: center;
//  display: flex;
//}
//
//.flex-1 {
//  flex: 1;
//}

.btn-xs {
  padding: 3px 16px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
}


.container.custom-container {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  min-width: 360px;
}

@include media-breakpoint-only(sm) {
  .container.custom-container {
    padding: 0 24px;
  }
}

@include media-breakpoint-only(xs) {
  .container.custom-container {
    padding: 0 16px;
  }
}


@include media-breakpoint-up(lg) {
  .w-100 > div {
    background-color: $main-bg;
  }
}

.dropdown-bot {
  .dropdown-toggle::after {
    border: none;
    background-image: url("../../../assets/whale-signals/down.svg");
  }
}

.snipe-block {
  color: $secondary;

  span img {
    margin: 0 0 0 4px;
  }

  img.tick {
    margin-top: 3px;
  }
}

@include media-breakpoint-only(sm) {
  .snipe-block {
    margin-top: 40px;
  }
}

@include media-breakpoint-only(xs) {
  .snipe-block {
    margin-top: 24px;
  }
}


/* TABLE */

.rounded-16 {
  border-radius: 16px;
}

.table-whale {
  border-collapse: collapse;
  width: 1360px;
  //width: 100%;
  font-size: 12px;
  margin-bottom: 0;

  th {
    padding: 16px 20px;
    background: $table-header-bg;
    font-weight: 500;
    line-height: 16px;
    border: 0;
  }

  th:first-child {
    padding: 16px 20px 16px 16px;
    border-top-left-radius: 16px;
  }

  th:last-child {
    padding: 16px 16px 16px 20px;
    border-top-right-radius: 16px;
  }

  td {
    border: 0;
    border-top: 1px solid var(--separator-color-with-transparency, rgba(84, 84, 88, 0.30));
    padding: 30px 20px;
    vertical-align: middle;
  }

  td:first-child {
    padding: 30px 20px 30px 16px;
  }

  td:last-child {
    padding: 30px 16px 30px 20px;
  }


  tr > th:first-child, tr > td:first-child {
    position: sticky;
    left: 0;
  }

  .token {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.24px;

    img {
      margin-right: 4px;
    }
  }
}

.plus {
  color: $green;
}

.minus {
  color: $red;
}


/* STATES  */

.error-contrainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 40px 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

}

.empty-data-contrainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.408px;
  padding: 40px 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  font-size: 17px;
  padding: 40px 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

}

.loader-container img {
  animation: custom-loader-animation 1s infinite linear; /* Определите анимацию для изображения */
}

@keyframes custom-loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* MODAL */
//.modal-title {
//  font-size: 28px
//}
//
//.modal-body {
//  padding: 0 30px 30px 30px;
//
//  .items {
//    display: flex;
//    flex-direction: column;
//    align-items: flex-start;
//    gap: 10px;
//    align-self: stretch;
//  }
//
//
//  .item {
//    background: $secondary-bg;
//    display: flex;
//    padding: 12px 16px;
//    align-items: flex-start;
//    gap: 12px;
//    align-self: stretch;
//    border-radius: 12px;
//    font-size: 13px;
//    font-weight: 400;
//    line-height: 18px;
//    letter-spacing: -0.078px;
//  }
//}
//
//.modal-header .btn-close {
//  width: 36px;
//  height: 36px;
//  padding: 0;
//  margin-right: 0;
//}


.accordion {
  margin: 20px 0 0 0;
  //padding: 20px 0 16px 0;
  border-top: 1px solid var(--label-color-dark-quaternary, rgba(98, 98, 98, 0.24));
  border-bottom: 1px solid var(--label-color-dark-quaternary, rgba(98, 98, 98, 0.24));

  .accordion-button {

    color: var(--label-color-dark-primary, #FFF);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 127.273% */
    letter-spacing: 0.35px;
  }

  .accordion-button:not(.collapsed) {
    background: $main_bg;
    box-shadow: none;
  }

  .accordion-button:focus {
    border: 0;
  }

  .accordion-button.collapsed {

  }

  ol {
    padding: 0;
    margin: 0 0 0 12px;
  }

  ol li {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.078px;
    margin-bottom: 16px;
  }
}

//@include media-breakpoint-only(xs) {
//  .modal-dialog {
//    margin-right: 16px;
//    margin-left: 16px;
//  }
//  .modal-header {
//    padding: 24px 16px 20px 16px;
//  }
//  .modal-body {
//    padding: 0 16px 24px 16px;
//  }
//  .modal-title {
//    font-size: 22px
//  }
//}

/* HELPER */

.clocki {
  margin-bottom: -1px;
}

.error-text {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.408px;
}


.tooltip-inner {
  p {
    padding: 5px 0 0 0;
    margin: 0;
  }
}

.btn-group-vertical {
  gap: 2px;
  background: rgba(84, 84, 88, 0.30);
  border-radius: 100px;
}

.success-message {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  position: fixed;
  top: 160px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 15px 24px;
  z-index: 999;
  border-radius: 100px;
  background: $secondary-bg;
  box-shadow: 0 4px 20px 0 rgba(255, 255, 255, 0.15);
}


.err-message {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  position: fixed;
  top: 160px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 15px 24px;
  z-index: 999;
  border-radius: 100px;
  background: $red;
  box-shadow: 0 4px 20px 0 rgba(255, 255, 255, 0.15);
}


/* **/
.one-signal-md {
  background: $main-bg;

}

@import "signalAnomaly";
@import "signalChange";
@import "signalInitialLiquidity";
@import "signalMetrics";
@import "signalName";
@import "signalSecurity";
@import "signalTime";
