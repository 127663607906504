body, html, #root {
  height: 100%;
}

body {
  //background: url("../assets/images/BG.png") center bottom no-repeat;
  //background-size: cover;
}

svg,
img {
  max-width: 100%;
  height: auto;
}

#root {
  > div {
    min-height: 100%;
  }
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.cur-pointer {
  cursor: pointer;

  &.with-hover:hover {
    opacity: .7;
  }
  &.with-hover-bg:hover {
    @extend .bg-semi-transparent-10;
  }
}

.rounded-1px {border-radius: 1px}
.rounded-2px {border-radius: 2px}
.rounded-3px {border-radius: 3px}
.rounded-4px {border-radius: 4px}
.rounded-5px {border-radius: 5px}
.rounded-6px {border-radius: 6px}
.rounded-7px {border-radius: 7px}
.rounded-8px {border-radius: 8px}
.rounded-9px {border-radius: 9px}
.rounded-10px {border-radius: 10px}
.rounded-30 {border-radius: 30px}

.border-solid {border-style: solid}

.form-group {
  position: relative;
}

.transition {
  transition: all .2s ease;
}

.img-cover {
  object-fit: cover;
}

.blur-10 {filter: blur(10px)}
.blur-5 {filter: blur(5px)}
.blur-3 {filter: blur(3px)}

.absolute-item {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.sticky {
  position: sticky;
  background: rgba($dark, 0.5);
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.no-filter {
  .sticky {
    backdrop-filter: none;
  }
}

.dropdown {
  > button {
    &:after {
      display: none;
    }
    &:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
    &:active,
    &.show {
      background-color: $gray-800 !important;
      border-color: $gray-800 !important;
    }
  }
  .dropdown-menu {
    z-index: 1050;
    //background-color: $gray-800;
    //border-color: $gray-800;
  }
  .dropdown-item {
    background-color: $gray-800;
    color: $white;
    font-size: 14px;
  }
}

.carousel-inner {
  height: 100%;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: $white;
  border: none;
  margin: 0 4px;
  opacity: .2;
  transition: all .2s ease;
  &:hover,
  &.active {
    opacity: 1;
    background: $white;
  }
  //&.active {
  //  width: 36px;
  //}
}

.btn-light {
  color: $black;
}

.full-page {
  height: 100vh;
}

.bg-semi-transparent {
  background: rgba($gray-500, .65);
  &.btn {
    background: rgba($gray-500, .65) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($gray-500, .5) !important;
    border: none;
  }
}
.bg-semi-transparent-10 {
  background: rgba($gray-400, .10);
  &.btn {
    background: rgba($gray-400, .10) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($gray-400, .05) !important;
    border: none;
  }
}
.bg-semi-transparent-25 {
  background: rgba($gray-300, .25);
  &.btn {
    background: rgba($gray-300, .25) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($gray-300, .1) !important;
    border: none;
  }
}
.bg-semi-danger {
  background: rgba($danger, .65);
  &.btn {
    background: rgba($danger, .65) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($danger, .5) !important;
    border: none;
  }
}
.bg-semi-danger-10 {
  background: rgba($danger, .12);
  &.btn {
    background: rgba($danger, .12) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($danger, .05) !important;
    border: none;
  }
}
.bg-semi-success {
  background: rgba($success, .65);
  &.btn {
    background: rgba($success, .65) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($success, .5) !important;
    border: none;
  }
}
.bg-semi-success-10 {
  background: rgba($success, .10);
  &.btn {
    background: rgba($success, .10) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($success, .05) !important;
    border: none;
  }
}
.bg-semi-primary {
  background: rgba($primary, .65);
  &.btn {
      background: rgba($primary, .65) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($primary, .5) !important;
    border: none;
  }
}
.bg-semi-primary-10 {
  background: rgba($primary, .10);
  &.btn {
      background: rgba($primary, .10) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($primary, .05) !important;
    border: none;
  }
}
.bg-semi-teal {
  background: rgba($teal, .65);
  &.btn {
      background: rgba($teal, .65) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($teal, .5) !important;
    border: none;
  }
}
.bg-semi-teal-10 {
  background: rgba($teal, .10);
  &.btn {
      background: rgba($teal, .10) !important;
    border: none;
  }
  &.btn:hover,
  &.cur-pointer:hover {
    background: rgba($teal, .05) !important;
    border: none;
  }
}

#assets-card {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.token-logo {
  position: relative;
  img {
    border-radius: 1000000px;
  }
  &--double {
    img {
      width: 81%;
      height: 81%;
      position: absolute;

      &:first-child {
        top: 0;
        left: 0;
      }
      &:last-child {
        bottom: 0;
        right: 0;
      }
    }
    .token-logo--main-text {
      position: absolute;
      left: 0;
      top: 0;
      width: 81%;
      height: 81%;
    }
    .token-logo--second-text {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 81%;
      height: 81%;
    }
  }
}

.operation {
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: $text-muted;
    border-radius: 10px;
  }

  &.operation-receive {
    &:before {
      background: $blue;
    }
  }
  &.operation-send {
    &:before {
      background: $purple;
    }
  }
  &.operation-buy {
    &:before {
      background: $success;
    }
  }
  &.operation-sell {
    &:before {
      background: $danger;
    }
  }
}

#assets-card {
  position: relative;
}

#assets-actions {
  position: fixed;
  bottom: $spacer;
  left: $spacer;
  width: calc(100% - #{$spacer * 2});

  .btn {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      max-width: 48px;
      min-width: 48px;
      padding: 0;
    }
  }
}

.qr-code {
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.swap-separator {
  position: relative;
  text-align: center;
  height: 40px;
  width: 100%;

  img {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top : 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 8px;
    margin-top: -4px;
    background: $gray-900;
  }

  &.between-cards {
    margin: -5px 0;
    z-index: 10;
    img {
      top: -2.5px;
      margin-left: -22.5px;
      border-radius: 100px;
    }
  }

  &.in-modal {
    margin: -10px 0;
    img {
      top: -5px;
      margin-left: -25px;
      border-radius: 100px;
    }
    &:before {
      background: $card-bg;
    }
  }
}

.btn-transparent {
  &:hover,
  &:active,
  &:focus {
    border-color: transparent !important;
    outline: none !important;
  }
}

.outline-none {
  outline: none !important;
}

.blurred-row {
  filter: blur(3px);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $card-bg, transparent);
  }
}

.will-rotate {
  transition: all .2s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

.btn.card {
  &:hover,
  &:active,
  &:focus {
    &:not([class*="bg-"]) {
      background: $card-bg !important;
    }
    box-shadow: none !important;
  }
}

.spin {
  animation: spin 3s linear infinite;
  &.faster {
    animation-duration: .3s;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.qr-code-logo {
  width: 20%;
  height: 15%;
  background: #fff;
  border: 2px solid #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.full-page {
  & > .MuiBox-root,
  & > .MuiBox-root > .MuiBox-root {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    & > .MuiScopedCssBaseline-root {
      max-height: 100%;
    }
  }
}

.MuiBadge-root {
 & + svg {
    max-height: 30px;
  }
}

.MuiScopedCssBaseline-root {
  background-color: $dark !important;
}
.MuiAppBar-root,
[class*='MuiPaper-root-MuiAppBar-root'] {
  background-color: $dark !important;
}
.MuiButton-text.MuiButton-textPrimary.MuiButton-fullWidth,
.MuiButtonBase-root.MuiButton-containedPrimary {
  background-color: $white !important;
  color: $dark !important;
}
.MuiButtonBase-root.MuiButton-containedPrimary + .MuiButtonBase-root {
  background: transparent !important;
  border: 1px solid $white !important;
  color: $white !important;
}

.card {
  .input-group-text,
  .form-control {
    @extend .bg-semi-transparent-10;
    border-width: 0 !important;
    &::-ms-input-placeholder {
      color: $gray-400 !important;
    }
    &::placeholder {
      color: $gray-400 !important;
      opacity: 1; /* Firefox */
    }

    &:focus {
      @extend .bg-semi-transparent-25;
      border-width: 0 !important;
      & + .input-group-text {
        @extend .bg-semi-transparent-25;
      }
    }
  }
  .input-group-text {
    margin-left: 0 !important;
  }
  .form-control.is-invalid {
    @extend .bg-semi-danger-10;
    border-width: 0 !important;
    & + .input-group-text {
      @extend .bg-semi-danger-10;
    }
  }
}

.styled-toggle {
  .react-toggle-track {
    width: 40px;
    background: rgba($gray-200, .3);
  }
  .react-toggle-thumb {
    border-color: $gray-200;
    box-shadow: none !important;
  }
  &.react-toggle--checked {
    .react-toggle-track {
      background: $success;
    }
    .react-toggle-thumb {
      left: 17px;
      border-color: $success;
    }
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: $success;
  }
  &:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: rgba($gray-200, .3);
  }
}

#snipe-settings {
  .input-group-text,
  .form-control,
  .wd-50p.card {
    padding-left: 10px;
    padding-right: 10px;
  }
  input[type="number"] {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.modal-dialog {
  max-height: 95%;
}

.form-control {
  outline: none !important;
  box-shadow: none !important;
}

.input-group.rounded-2 > :first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.input-group.rounded-2 > :last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.alert-box-shadow {
  box-shadow: 0 4px 20px 0 rgba(255, 255, 255, 0.15);
}

.notification {
  animation: slideIn .3s;
  transform: translateZ(11000px);
}

.notification-out {
  animation: slideOut .3s;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.card {
  .form-control.border-focus {
    padding-left: 30px !important;
    border-style: solid !important;
    border-color: $gray-600 !important;
    border-width: 1px !important;
    &.border-danger,
    &:focus {
      border-style: dashed !important;
      border-color: $white !important;
      &.border-danger {
        border-color: $danger !important;
      }
    }
  }
}

.seed-phrase-label {
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -10px;
}

.form-control.is-invalid {
  background: rgba($danger, .12) !important;
}

.input-group.is-invalid {
  .form-control,
  .input-group-text {
    background: rgba($danger, .12) !important;
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}

.form-control {
  &::-webkit-input-placeholder {
    color: $gray-400;
  }

  &::-moz-placeholder {
    color: $gray-400;
  }

  &::placeholder {
    color: $gray-400;
  }
}

.text-truncate-gradient {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 35px;
    max-width: 50px;
    background: linear-gradient(-90deg, $card-bg 20%, transparent);
  }
}

.flex-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: row;
}

.bg-diamond-gradient {
  background: linear-gradient(90deg, #4FACFE 0%, #00F2FE 100%), #FFFFFF;
}

.bg-master-gradient {
  background: linear-gradient(139.08deg, #B37DDD 0%, #767BDD 100%), #FFFFFF;
}

.active-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  border: 1px solid #282828;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  background: url(../assets/icons/check.svg) center center no-repeat #282828;
  background-size: cover;
}

.sidenav-item:hover,
.sidenav-item:focus {
  background: $gray-700;
}

.tab-content {
  position: relative;
  .tab-element {
    opacity: 0;
  }
}

#onboarding {
  padding-bottom: 0 !important;
  > div {
    > div:nth-child(2) {
      z-index: 1000;
      position: absolute !important;
      overflow: auto !important;
      display: block !important;
      top: 0;
      left: 0;
      pointer-events: none;

      button {
        pointer-events: all;
      }

      *:not(button) {
        pointer-events: none;
      }
    }
    div:last-child {
      z-index: 999;
    }
  }
}
