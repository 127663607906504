.times {
  font-size: 12px;
}

@include media-breakpoint-between(sm, lg) {
  .time {
    height: 47px;
  }
}

@include media-breakpoint-only(xs) {
}

