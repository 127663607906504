.chain-label {
  position: absolute;
  bottom: 2px;
  right: -2px;
  width: 18px;
  height: 18px;
  border-radius: 1000px;
  border: 1px solid $card-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  &.is-robot {
    bottom: 0;
    font-size: 18px;
  }
}

.left-label {
  position: absolute;
  top: -4px;
  left: -2px;
  width: 22px;
  height: 22px;
  border-radius: 1000px;
  border: 1px solid $card-bg;
  background: $gray-600;
  color: $white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
